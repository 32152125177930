import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/profile/confirm-credit/credit.svg'


const _hoisted_1 = { class: "CCPassView" }
const _hoisted_2 = {
  key: 0,
  class: "CCPassView__success"
}
const _hoisted_3 = {
  key: 0,
  class: "creditImg-box"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  width: "250",
  src: _imports_0,
  alt: ""
}
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleFilled = _resolveComponent("CheckCircleFilled")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showSuccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h5", null, [
            _createElementVNode("span", null, [
              _createVNode(_component_CheckCircleFilled)
            ]),
            _createTextVNode(" 认证完成！")
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_form, {
      class: "CCPassView__form",
      "label-col": { span: 5 },
      "wrapper-col": { span: 14, offset: 1 }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "公司名称" }, {
          default: _withCtx(() => [
            _createElementVNode("h2", null, _toDisplayString(_ctx.enterprise.enterpriseName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "营业执照" }, {
          default: _withCtx(() => [
            (_ctx.creditImg)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("img", {
                    width: "250",
                    src: _ctx.creditImg,
                    alt: "营业执照图片",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vp(_ctx.creditImg)))
                  }, null, 8, _hoisted_4)
                ]))
              : (_openBlock(), _createElementBlock("img", _hoisted_5))
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "法人姓名" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.enterprise.legalPerson), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "统一社会信用代码" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.enterprise.socialCreditCode), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      visible: _ctx.previewVisible,
      footer: null,
      onCancel: _cache[1] || (_cache[1] = ($event: any) => {_ctx.previewVisible = false;})
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          alt: "营业执照图片",
          style: {"width":"100%"},
          src: _ctx.creditImg
        }, null, 8, _hoisted_6)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}