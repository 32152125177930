
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent<{}, {}, {}, {}>({
  name: 'ComfirmCreditFail',
  emits: ['toRetry'],
  setup(props, ctx) {
    const route = useRoute();
    const gobackTo = computed(() => route.query.goback || { name: 'profile.info' });
    return { gobackTo };
  },
});
