
import { defineComponent, computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useState } from '@/store';
import { UserMutationList } from '@/store/modules/user';
import Submit from './Submit.vue';
import Pass from './Pass.vue';
import Wait from './Wait.vue';
import Fail from './Fail.vue';

const statusMap: Record<string, number> = {
  NOCERT: 0,
  SUCCESS: 1,
  WAIT: 2,
  FAIL: 3,
};

export default defineComponent<{}, {}, {}, {}, any>({
  name: 'ConfirmCreditLayout',
  components: {
    Submit,
    Pass,
    Wait,
    Fail,
  },
  setup(props, ctx) {
    const route = useRoute();
    const store = useStore();
    const state = useState();

    const gobackTo = computed(() => route.query.goback || { name: 'profile.info' });

    const onRetry = () => {
      store.commit(`User/${UserMutationList.SET_ENTERPRISE}`, { ...state.enterprise.value, state: 'NOCERT' });
    };

    return {
      gobackTo,
      onRetry,
    };
  },
});
