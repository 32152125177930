
import { defineComponent, computed, onMounted, ref, watch } from 'vue';
import { CheckCircleFilled } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { UserMutationList } from '@/store/modules/user';
import { useState } from '@/store';

export default defineComponent<{}, {}, {}, {}>({
  name: 'ComfirmCreditPass',
  components: {
    CheckCircleFilled,
  },
  setup(prop, ctx) {
    const state = useState();
    const route = useRoute();
    const creditImg = ref('');
    const previewVisible = ref(false);

    const getCreditImg = async () => {
      // 换取企业执照图片
      const res = await request.get(createApiUrl('/newlinkSass/enterprise/get-img-url'), { params: { key: state.enterprise.value.licenseUrl } });
      creditImg.value = res.data;
    };

    const showSuccess = computed(() => !!route.query['show-success']);

    onMounted(getCreditImg);

    watch(
      () => state.enterprise.value,
      () => getCreditImg(),
    );

    return {
      creditImg,
      showSuccess,
      previewVisible,
    };
  },
});
