import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_pass = _resolveComponent("pass")!
  const _component_wait = _resolveComponent("wait")!
  const _component_fail = _resolveComponent("fail")!
  const _component_submit = _resolveComponent("submit")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer confirmCreditLayout" }, {
    title: _withCtx(() => [
      _createVNode(_component_router_link, {
        class: "link-goback",
        to: _ctx.gobackTo
      }, {
        default: _withCtx(() => [
          _createVNode(_component_app_icon, { type: "arrow-left" })
        ]),
        _: 1
      }, 8, ["to"]),
      _createTextVNode(" 企业认证 ")
    ]),
    default: _withCtx(() => [
      (_ctx.enterprise.state === 'SUCCESS')
        ? (_openBlock(), _createBlock(_component_pass, {
            key: 0,
            class: "confirmCreditLayout__content"
          }))
        : (_ctx.enterprise.state === 'WAIT')
          ? (_openBlock(), _createBlock(_component_wait, {
              key: 1,
              class: "confirmCreditLayout__content"
            }))
          : (_ctx.enterprise.state === 'FAIL')
            ? (_openBlock(), _createBlock(_component_fail, {
                key: 2,
                class: "confirmCreditLayout__content"
              }))
            : (_openBlock(), _createBlock(_component_submit, {
                key: 3,
                class: "confirmCreditLayout__content"
              }))
    ]),
    _: 1
  }))
}