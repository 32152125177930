
import { defineComponent, computed, reactive, ref, toRaw } from 'vue';
import { message, Form } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { request } from '@/utils/request';
import { UserMutationList } from '@/store/modules/user';
import { useState } from '@/store';
import router from '@/router';

export default defineComponent({
  name: 'ComfirmCreditStep1',
  setup(props, ctx) {
    const route = useRoute();
    const state = useState();
    const store = useStore();
    const uploading = ref(false);
    const submiting = ref(false);
    const fileVal = ref('');
    const serverError = ref('');
    const serverErrorVisible = computed({
      get() {
        return !!serverError.value;
      },
      set(val) {
        serverError.value = val ? serverError.value : '';
      },
    });

    const formData = reactive({
      enterpriseName: '',
      legalPerson: '',
      socialCreditCode: '',
      url: '',
      key: '',
    });

    const rules = {
      key: [{ required: true, message: '请上传营业执照片', trigger: 'blur' }],
      enterpriseName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
      legalPerson: [{ required: true, message: '请输入企业法人姓名', trigger: 'blur' }],
      socialCreditCode: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
      url: [{ required: true, message: '请上传营业执照片', trigger: 'blur' }],
    };

    const onChooseCreditImage = async (event: Event) => {
      const ele = (event.target as HTMLInputElement);
      if (!ele.files!.length) {
        return;
      }

      const file = ele.files![0];

      // 上传图片
      let res: any;
      uploading.value = true;

      try {
        const fd = new FormData();
        fd.append('file', file);
        res = await request.put(createApiUrl('/newlinkSass/enterprise/to-legalize-enterprise-orc'), fd, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        uploading.value = false;
      }

      const rd = res.data;
      formData.key = rd.imgEnterpriseKey;
      formData.url = rd.imgUrl;
      formData.enterpriseName = rd.entname;
      formData.socialCreditCode = rd.creditCode;
      formData.legalPerson = rd.frname;
    };

    const editorForm = ref<any>(null as any);

    const onSubmit = () => {
      editorForm.value.validate()
        .then(() => {
          submiting.value = true;
          return request.put(createApiUrl('/newlinkSass/enterprise/to-legalize-enterprise'), formData);
        })
        .then((res: any) => {
          message.success('认证信息提交完成,请等待管理员审核.');
          store.dispatch(`User/${UserMutationList.SET_ENTERPRISE}`, { ...state.enterprise.value, ...res.data, state: 'WAIT' });
          router.replace({ name: 'profile.confirm_credit' });
          return store.dispatch('User/getUserDetail');
        })
        .catch((e: any) => {
          if (e.code && e.code === 2333) {
            serverError.value = e.message;
          } else {
            serverError.value = '';
            message.error(isValidateError(e) ? firstError(e) : e.message);
          }
        })
        .finally(() => {
          submiting.value = false;
        });
    };

    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14, offset: 1 },

      formData,
      fileVal,
      rules,

      uploading,
      editorForm,
      onChooseCreditImage,

      serverError,
      serverErrorVisible,

      submiting,
      onSubmit,
    };
  },
});
