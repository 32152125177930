import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "setOrModifyPwd__content" }
const _hoisted_2 = { class: "setOrModifyPwd__codeWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer setOrModifyPwd__wrap" }, {
    title: _withCtx(() => [
      _createVNode(_component_router_link, {
        class: "link-goback",
        to: {name: 'profile.info'}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_app_icon, { type: "arrow-left" })
        ]),
        _: 1
      }, 8, ["to"]),
      _createTextVNode(" 设置/ 修改密码 ")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form, {
          ref: "editorForm",
          class: "setOrModifyPwd__form",
          model: _ctx.formData,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol,
          rules: _ctx.rules
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "手机号码",
              required: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.userInfo.mobile), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "短信验证码",
              required: "",
              name: "verificationCode"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_a_input, {
                    value: _ctx.formData.verificationCode,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.verificationCode) = $event)),
                    type: "number",
                    autocomplete: "false",
                    placeholder: "验证码"
                  }, null, 8, ["value"]),
                  _createVNode(_component_a_button, {
                    type: "primary",
                    ghost: "",
                    disabled: _ctx.codeSending || _ctx.sendCounting,
                    onClick: _ctx.onSendCaptcha
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.sendText), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "设置新密码",
              required: "",
              name: "saasLoginPassword",
              help: "密码须为8-20位，包含字母、数字、符号至少两种"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formData.saasLoginPassword,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.saasLoginPassword) = $event)),
                  type: "password",
                  name: "newpassword"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "确认新密码",
              required: "",
              name: "confirmPwd"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formData.confirmPwd,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.confirmPwd) = $event)),
                  type: "password",
                  name: "newpassword_confirm"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { "wrapper-col": { span: 14, offset: 6 } }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.onSubmit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("提交")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "label-col", "wrapper-col", "rules"])
      ])
    ]),
    _: 1
  }))
}