
import { computed, defineComponent, onMounted, reactive, ref, toRaw, watch } from 'vue';
import { Form, message } from 'ant-design-vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { not } from '@/utils/illuminate';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { CAPTCHA_TYPE } from '@/utils/constants';
import { useCountdown } from '@/components/fragments/countdown';

export default defineComponent({
  name: 'AutoEditor',
  setup(props, ctx) {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const countdownState = useCountdown();

    const userInfo = computed(() => store.state.User.userInfo);

    const formData = reactive({
      verificationCode: '',
      saasLoginPassword: '',
      confirmPwd: '',
    });

    const rules = {
      verificationCode: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { pattern: /^\d{6}$/, message: '验证码错误', trigger: 'blur' },
        { validator: () => Promise.resolve(), trigger: 'change' },
      ],
      saasLoginPassword: [
        { required: true, message: '请输入新密码', trigger: 'blur' },
        { pattern: /^\S{8,20}$/, message: '密码必须为8-20位', trigger: 'blur' },
        {
          validator: (rule: any, value: any) => {
            const hasNum = [/[a-zA-Z]/, /\d/, /[^a-zA-Z0-9]/].reduce((pre, item) => pre + (item.test(value) ? 1 : 0), 0);
            return hasNum < 2 ? Promise.reject('密码须包含字母、数字、符号至少两种!') : Promise.resolve();
          },
          trigger: 'blur',
        },
        { validator: () => Promise.resolve(), trigger: 'change' },
      ],
      confirmPwd: [
        { required: true, message: '请输入确认密码', trigger: 'blur' },
        {
          validator: (rule: any, value: any) => (formData.saasLoginPassword !== formData.confirmPwd ? Promise.reject('与新密码不一致, 请重新输入') : Promise.resolve()),
          trigger: 'blur',
        },
        { validator: () => Promise.resolve(), trigger: 'change' },
      ],
    };

    watch(() => formData.verificationCode, val => {
      if (val.toString().length > 6) formData.verificationCode = val.toString().slice(0, 6);
    });

    const editorForm = ref<typeof Form>(null as any);
    const codeSending = ref(false);

    const onSendCaptcha = async () => {
      if (countdownState.counting.value || codeSending.value) return;

      codeSending.value = true;
      try {
        await request.get(createApiUrl('/newlinkSass/enterprise/captchar/mobile/send/code'), {
          params: { mobile: userInfo.value.mobile, type: CAPTCHA_TYPE.SET_PWD },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        codeSending.value = false;
      }

      countdownState.start();
      message.success('验证码发送完成');
    };


    const onSubmit = () => {
      editorForm.value.validate()
        .then(() => request.put(createApiUrl('/newlinkSass/personal/login-user-update-pwd'), { ...not(formData, ['confirmPwd']), mobile: userInfo.value.mobile }))
        .then((res: any) => {
          message.success('密码保存完成');
          router.replace({ name: 'profile.info' });
        })
        .catch((e: any) => {
          message.error(isValidateError(e) ? firstError(e) : e.message);
        });
    };

    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 14, offset: 1 },
      limitOptions: [
        { label: '不限', value: false },
        { label: '限制金额', value: true },
      ],
      userInfo,

      rules,
      editorForm,
      formData,

      sendCounting: countdownState.counting,
      sendText: countdownState.currentText,

      codeSending,
      onSendCaptcha,
      onSubmit,
    };
  },
});
