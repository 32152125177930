
import { defineComponent, reactive, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export default defineComponent({
  name: 'UserInfoView',
  components: {},
  setup(props, ctx) {
    const detail = reactive<any>({});

    const loadDetail = async () => {
      let resData: any;
      try {
        ({ data: resData } = await request.get(createApiUrl('/newlinkSass/personal/find-login-user-detail')));
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      Object.assign(detail, resData);
    };

    onMounted(() => {
      loadDetail();
    });

    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 10, offset: 1 },

      detail,
      loadDetail,
    };
  },
});
